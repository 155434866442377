import "./2";
import "./3";
import "./4";

const elements = {
  logo: ".custom-logo-link",
};
document.addEventListener("DOMContentLoaded", () => {
  Object.keys(elements).forEach((key) => {
    elements[key] = document.querySelectorAll(elements[key]);
  });
  if (
    document.querySelector("main[id^='landing-']") &&
    !document.querySelector("main#landing-1")
  ) {
    elements.logo.forEach((logo) => {
      logo.href = "";
    });
  }

  const list = {
    "main#landing-1": {
      message: "Оплата частинами",
    },
    "main#landing-2": {
      message: "Страх перед операцією",
    },
    "main#landing-3": {
      message: "Посадкова сторінка",
      "doctor-name": params.doctorName || "Лікар не обраний",
    },
    "main#landing-4": {
      message: `Виїзна консультація: ${
        document.querySelector("#hero .hero-text p:not(:empty) strong")
          ?.textContent
      }`,
      "event-time":
        document.querySelector("#hero .hero-text .data")?.textContent ||
        "Час візиту не вказаний",
    },
  };
  const forms = [
    document.querySelector(".backdrop#appointment-schedule"),
    document.querySelector("#cta form"),
  ];
  Object.keys(list).forEach((key) => {
    if (document.querySelector(key)) {
      forms.forEach((form) => {
        if (!form) return;
        Object.keys(list[key]).forEach((input) => {
          if (form.querySelector(`input[name="${input}"]`)) {
            form.querySelector(`input[name="${input}"]`).value =
              list[key][input];
          }
        });
      });
    }
  });
});
document.addEventListener("wpcf7mailsent", function (event) {
  fbq("track", "Lead");
});
