async function getCartItems() {
  try {
    const response = await fetch("/wp-admin/admin-ajax.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: "action=fetch_cart_items",
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    }
    return null;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}
function refreshProductsList(data = []) {
  const container = document.querySelector(
    ".backdrop.mini-cart .window .products"
  );
  if (data?.length > 0) {
    const list = document.createElement("ul");
    list.classList.add("cart-list");
    data.forEach((product) => {
      const item = document.createElement("li");
      item.classList.add("cart-item");
      item.innerHTML = `
				<div class="product-image">
					<img src="${product.image_url}" alt="${product.title}">
				</div>
				<div class="product-details">
					<h4 class="product-title">${product.product_name}</h4>
					<p class="product-price"><strong>${product.price} грн</strong></p>
					<button class="remove-item-icon" data-product-id="${product.id}">
					<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9L9 1M9 9L1 1" stroke="#A5A5A5" stroke-linecap="square" stroke-linejoin="round"></path></svg>
					</button>
				</div>
			`;
      list.appendChild(item);
    });
    const title = document.createElement("h5");
    title.classList.add("cart-title");
    title.innerHTML = "Список послуг";
    container.innerHTML = "";
    container.appendChild(title);
    container.appendChild(list);

    // Add event listeners to the remove buttons
    document.querySelectorAll(".remove-item-icon").forEach((button) => {
      button.addEventListener("click", (e) => {
        const productId = button.getAttribute("data-product-id");
        removeCartItem(productId);
      });
    });
  } else {
    container.innerHTML = "Жодного товару в кошику";
  }
}

import IMask from "imask";

const phoneInputs = document.querySelectorAll(
  '.backdrop.mini-cart input[type="tel"]'
);
phoneInputs.forEach((input) => {
  const maskOptions = {
    mask: "+380 00 000 00-00",
    lazy: false,
  };
  const mask = IMask(input, maskOptions);
});

function removeCartItem(productId) {
  fetch("/wp-admin/admin-ajax.php", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `action=remove_cart_item&product_id=${productId}`,
  })
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success) {
        const data = await getCartItems();
        refreshProductsList(data);
        updateCounter();
      } else {
        console.error("Failed to remove item from cart:", data.error);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
function clearCart() {
  fetch("/wp-admin/admin-ajax.php", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: "action=clear_cart",
  })
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success) {
        const data = await getCartItems();
        refreshProductsList(data);
        updateCounter();
        document
          .querySelectorAll(".backdrop.mini-cart .form input")
          .forEach((el) => {
            el.value = "";
          });
      } else {
        console.error("Failed to clear the cart:", data.error);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

document.addEventListener("DOMContentLoaded", async () => {
  let data = await getCartItems();
  refreshProductsList(data);
  popup_init();
  document
    .querySelector(".backdrop.mini-cart .form")
    .addEventListener("submit", async (e) => {
      e.preventDefault();
      clearCart();
      data = await getCartItems();
      if (data.length < 1) return;
      const bot = {
        id: "-4257231388",
        token: "7234930983:AAHouHM1mTlBglwqJw-uDpzWD5jpje3v2Fs",
      };
      const message = await prepearMessage(data);
      send(bot, message);
    });
});
document.addEventListener("popup-open", async ({ detail: name }) => {
  if (name === "mini-cart") {
    const data = await getCartItems();
    refreshProductsList(data);
  }
});

function popup_init() {
  document.querySelectorAll(".popup-open").forEach((e) => {
    e.addEventListener("click", () => {
      document.dispatchEvent(
        new CustomEvent("popup-open", { detail: e.classList[1] })
      );
      let t = document.querySelector(`.backdrop.${e.classList[1]}`),
        c = () => {
          t?.classList.remove("show"),
            t?.querySelector(".close-button")?.removeEventListener("click", c),
            window.removeEventListener("click", s),
            document.documentElement.classList.add("interaction-disabled"),
            setTimeout(() => {
              document.documentElement.classList.remove("interaction-disabled");
            }, parseInt(getComputedStyle(t)?.getPropertyValue("--_show-speed")) || 500);
        },
        s = (e) => {
          e.target == t && c();
        };
      t &&
        (t.classList.add("show"),
        t.querySelector(".close-button")?.removeEventListener("click", c),
        window.removeEventListener("click", s),
        setTimeout(() => {
          t.querySelector(".close-button")?.addEventListener("click", c),
            window.addEventListener("click", s);
        }, parseInt(getComputedStyle(t)?.getPropertyValue("--_show-speed")) || 500));
    });
  });
}

jQuery(document).ready(function ($) {
  // Замість 'form.cart' вказати клас форми або кнопки додавання до кошика
  $("body").on("added_to_cart", function () {
    updateCounter();
  });
});
function updateCounter() {
  $.ajax({
    url: wc_add_to_cart_params.ajax_url,
    type: "POST",
    data: {
      action: "update_cart_count",
    },
    success: function (response) {
      if (response.success) {
        if (response.data > 0) {
          const countWrapper = document.querySelectorAll(".cart-counter");
          countWrapper.forEach((el) => {
            el.innerHTML = `<span class="count">${response.data}</span>`;
          });
        } else {
          const countWrapper = document.querySelectorAll(".cart-counter");
          countWrapper.forEach((el) => {
            el.innerHTML = "";
          });
        }
      }
    },
  });
}
function send({ id, token }, message) {
  const url = `https://api.telegram.org/bot${token}/sendMessage`;
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      chat_id: id,
      text: message,
      parse_mode: "HTML",
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
const prepearMessage = (data) => {
  let message = "Замовлення з сайту:\n"; // змінюємо const на let
  data.forEach((product) => {
    message += `<b>${product.product_name}</b> - ${product.price} грн\n\n`; // додавання рядка до message
  });
  const totalSum = data.reduce((acc, item) => {
    return acc + parseInt(item.price);
  }, 0);
  message += `Сума: ${totalSum} грн\n\n`;
  const dict = {
    name: "Ім'я",
    phone: "Телефон",
    email: "Пошта",
  };
  document.querySelectorAll(".backdrop.mini-cart .form input").forEach((el) => {
    message += `<b>${dict[el.getAttribute("name")]}:</b> ${el.value}\n`;
  });
  return message;
};
