export default async function get_field(field, id) {
  const QP = {
    action: "load_more_posts",
    field,
    id,
  };
  const req = await fetch(params.ajax_url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(QP),
  });
  const res = await req.json();
  return res;
}
