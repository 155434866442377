// $(document).ready(function() {
//     // Викликаємо функцію для першого елемента списку consultation
//     openAccordion($('.consultation-list__item:first'), '.consultation-list__item', '.read-more-wrapper');

//     // Викликаємо функцію для першого елемента списку FAQ (якщо потрібно)

//     // На клік відкриваємо або закриваємо вміст елемента consultation
//     $('.consultation-list__item').click(function () {
//         openAccordion($(this), '.consultation-list__item', '.read-more-wrapper');
//     });

//     // На клік відкриваємо або закриваємо вміст елемента FAQ
//     $('.faq-list__item').click(function () {
//         openAccordion($(this), '.faq-list__item', '.read-more-wrapper');
//     });

//     // Універсальна функція для відкриття або закриття акордеону
//     function openAccordion(element, itemClass, contentClass) {
//         // Закриваємо попередній відкритий елемент
//         $(itemClass + '.open').not(element).removeClass('open').find(contentClass).slideUp();

//         // Відкриваємо або закриваємо вміст поточного елемента
//         element.toggleClass('open').find(contentClass).slideToggle();
//     }

//     $('#loadMore').click(function () {
//         $('.faq-list__item:hidden').slice(0, 7).slideDown();

//         // Приховуємо кнопку, якщо більше немає прихованих елементів
//         if ($('.faq-list__item:hidden').length === 0) {
//             $(this).fadeOut();
//         }
//     });

//         // Отримання URL поточної сторінки
//         const currentUrl = window.location.href;

//         $(".nav-list__item").click(function(){
//             // Знаходимо підменю, пов'язане з клікнутим елементом
//             const submenu = $(this).find(".header-dropdown");

//             // Закриваємо всі інші підменю, які не пов'язані з цим елементом
//             $(".header-dropdown").not(submenu).slideUp();

//             // Показуємо або приховуємо підменю, пов'язане з цим елементом
//             submenu.slideToggle();

//             // Забираємо клас "active" з усіх інших елементів
//             $(".header-dropdown__link").removeClass("active");

//             // Додаємо клас "active" до елементу .header-dropdown__link відповідного підменю
//             $(this).find(".header-dropdown__link").toggleClass("active");

//             // Додаємо клас "active" до елементу .header-dropdown__link, що відповідає поточній сторінці
//             $(".header-dropdown__link").each(function() {
//                 if ($(this).attr("href") === currentUrl) {
//                     $(this).addClass("active");
//                 }
//             });
//         });

//         // Перевірка, чи є посилання на поточній сторінці і додавання класу "active" відповідно
//         $(".header-dropdown__link").each(function() {
//             if ($(this).attr("href") === currentUrl) {
//                 $(this).addClass("active");
//             }
//         });

//     $('.languages-wrapper').on('click', function(event) {
//         event.preventDefault();
//         $('.languages-dropdown').slideToggle();
//         $('.svg-rotate').toggleClass('rotate');
//     });

// });

$(document).ready(function () {
  // Викликаємо функцію для першого елемента списку consultation
  openAccordion(
    $(".consultation-list__item:first"),
    ".consultation-list__item",
    ".read-more-wrapper"
  );

  // Викликаємо функцію для першого елемента списку FAQ (якщо потрібно)

  // На клік відкриваємо або закриваємо вміст елемента consultation
  $(".consultation-list__item").click(function () {
    openAccordion($(this), ".consultation-list__item", ".read-more-wrapper");
  });

  // На клік відкриваємо або закриваємо вміст елемента FAQ
  $(".faq-list__item").click(function () {
    openAccordion($(this), ".faq-list__item", ".read-more-wrapper");
  });

  // Універсальна функція для відкриття або закриття акордеону
  function openAccordion(element, itemClass, contentClass) {
    // Закриваємо попередній відкритий елемент
    $(itemClass + ".open")
      .not(element)
      .removeClass("open")
      .find(contentClass)
      .slideUp();

    // Відкриваємо або закриваємо вміст поточного елемента
    element.toggleClass("open").find(contentClass).slideToggle();
  }

  // $("#loadMore").click(function () {
  //   $(".faq-list__item:hidden").slice(0, 7).slideDown();

  //   // Приховуємо кнопку, якщо більше немає прихованих елементів
  //   if ($(".faq-list__item:hidden").length === 0) {
  //     $(this).fadeOut();
  //   }
  // });

  // Отримання URL поточної сторінки
  const currentUrl = window.location.href;

  // // На ховер відкриваємо або закриваємо вміст елемента меню
  // $(".nav-list__item").hover(
  //   function () {
  //     // Знаходимо підменю, пов'язане з наведенням миші
  //     const submenu = $(this).find(".header-dropdown");

  //     // Закриваємо всі інші підменю, які не пов'язані з цим елементом
  //     $(".header-dropdown").not(submenu).slideUp();

  //     // Показуємо підменю, пов'язане з цим елементом
  //     submenu.slideDown();

  //     // Забираємо клас "active" з усіх інших елементів
  //     $(".header-dropdown__link").removeClass("active");

  //     // Додаємо клас "active" до елементу .header-dropdown__link відповідного підменю
  //     $(this).find(".header-dropdown__link").toggleClass("active");

  //     // Додаємо клас "active" до елементу .header-dropdown__link, що відповідає поточній сторінці
  //     $(".header-dropdown__link").each(function () {
  //       if ($(this).attr("href") === currentUrl) {
  //         $(this).addClass("active");
  //       }
  //     });
  //   },
  //   function () {
  //     // Закриваємо підменю при виході миші
  //     $(this).find(".header-dropdown").slideUp();
  //   }
  // );

  // Перевірка, чи є посилання на поточній сторінці і додавання класу "active" відповідно
  $(".header-dropdown__link").each(function () {
    if ($(this).attr("href") === currentUrl) {
      $(this).addClass("active");
    }
  });

  $(".languages-wrapper").on("click", function (event) {
    // event.preventDefault();
    $(".languages-dropdown").slideToggle();
    $(".svg-rotate").toggleClass("rotate");
  });
});

document.addEventListener("DOMContentLoaded", function () {
  function accordionTab() {
    const images = document.querySelectorAll(".consultations_image");
    const consultationList = document.querySelectorAll(
      ".consultation-list__item"
    );

    images.forEach((image) => {
      image.style.display = "none";
    });
    if (images.length > 0) {
      images[0].style.display = "block";
    }

    consultationList.forEach((item, index) => {
      item.addEventListener("click", () => fk(images, index));
    });
    window.addEventListener("resize", () => {
      if (
        window.innerWidth > 991 &&
        getComputedStyle(images[0]?.parentElement)?.display === "none"
      ) {
        images[0].parentElement.style.display = "block";
        consultationList.forEach((item, index) => {
          if (item.classList.contains("open")) {
            fk(images, index);
          }
        });
      } else if (
        getComputedStyle(images[0].parentElement)?.display === "block" &&
        window.innerWidth < 992
      ) {
        images[0].parentElement.style.display = "none";
      }
    });
    if (window.innerWidth < 992) {
      images[0].parentElement.style.display = "none";
    }
  }
  const fk = (images, index) => {
    if (window.innerWidth > 991) {
      images.forEach((image) => {
        image.style.display = "none";
      });
    }
    images[index].style.display = "block";
  };
  if (document.querySelectorAll(".consultations_image").length > 0) {
    accordionTab();
  }
});
