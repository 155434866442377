document.addEventListener("DOMContentLoaded", function () {
  if (
    document.querySelector("#cta form [type=submit]") &&
    document.querySelector("main[id^='landing']") &&
    !document.querySelector("main#landing-1")
  ) {
    document.querySelector("#cta form [type=submit]").value =
      "Записатись на консультацію";
  }
  document
    .querySelector("#whatIGot .list")
    ?.addEventListener("transitionend", function () {
      setHeight(["#whatIGot .list", "#whatIGot .image-thumb"]);
    });
  setHeight(["#whatIGot .list", "#whatIGot .image-thumb"]);
  window.addEventListener("resize", function () {
    setHeight(["#whatIGot .list", "#whatIGot .image-thumb"]);
  });
});
function setHeight([a, b]) {
  const [list, image] = [document.querySelector(a), document.querySelector(b)];
  if (!list || !image) return;
  if (window.innerWidth < 992) {
    image.style.height = "auto";
    return;
  }
  image.style.height = list.offsetHeight + "px";
}

function getDistanseFromCenter(e) {
  const { width, height } = this.getBoundingClientRect();
  const { offsetX, offsetY } = e;
  const x = ((offsetX - width / 2) / width) * 2;
  const y = ((offsetY - height / 2) / height) * 2;

  return { x, y };
}
document
  .querySelector("#map .image-thumb")
  ?.addEventListener("mousemove", function (e) {
    const { x, y } = getDistanseFromCenter.call(this, e);
    e.target.style.setProperty("--_x", x);
    e.target.style.setProperty("--_y", y);
  });
