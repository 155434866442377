let text;
const bot = {
  id: "-4257231388",
  token: "7234930983:AAHouHM1mTlBglwqJw-uDpzWD5jpje3v2Fs",
};

document.addEventListener(
  "wpcf7mailsent",
  function (event) {
    let id = event.detail.apiResponse["contact_form_id"];
    if (id === 6 || id === 258 || id === 259) {
      text = "<b>Контактные данные клиента:</b>\n";
    } else if (id === 1228 || id === 1227 || id === 1226) {
      text = "<b>Контактные данные клиента:</b>\n";
    } else if (id === 686 || id === 687 || id === 688) {
      text = "<b>Пошта клієнта:</b>\n";
    } else if (id === 681 || id === 682 || id === 683) {
      text = "<b>Пошта клієнта:</b>\n";
    } else {
      text = "<b>Контактные данные клиента:</b>\n";
    }
    text = fetchData(event, text);
    const tyWrapper = event.target
      .closest(".cta-wrapper__form")
      ?.querySelector(".ty-wrapper");
    if (tyWrapper) {
      tyWrapper.classList.remove("is-hidden");
    } else {
      console.warn("Не знайдено елемент .ty-wrapper");
    }
    if (text !== "") {
      send(bot, text);
    }
  },
  false
);
function fetchData(e, text) {
  e.detail.inputs.forEach((input) => {
    switch (input.name) {
      case "your-name":
        text += `Ім'я: <b>${input.value}</b>\n\n`;
        break;
      case "your-name-secondary":
        text += `Ім'я: <b>${input.value}</b>\n\n`;
        break;
      case "your-surname":
        text += `Прізвище: <b>${input.value}</b>\n\n`;
        break;
      case "phone":
        text += `Номер телефона: <b>${input.value}</b>\n\n`;
        break;
      case "secondary-number":
        text += `Номер телефона: <b>${input.value}</b>\n\n`;
        break;
      case "email-form":
        text += `Пошта: <b>${input.value}</b>\n\n`;
        break;
      case "secondary-email":
        text += `Пошта: <b>${input.value}</b>\n\n`;
        break;
      case "doctor-name":
        if (input.value) text += `Запис до <b>${input.value}</b>\n\n`;
        break;
      case "event-time":
        if (input.value) text += `Час події: <b>${input.value}</b>\n\n`;
        break;
      case "town-name":
        if (input.value) text += `Місто запису: <b>${input.value}</b>\n\n`;
        break;
      case "textarea":
        if (input.value) text += `Повідомлення: <b>${input.value}</b>\n\n`;
        break;
      case "message":
        if (input.value) text += `Про заявку: <b>${input.value}</b>\n\n`;
        break;
      // додайте інші поля форми за потреби
      default:
        text += `<b>${input.name}:</b> ${input.value}\n\n`;
    }
  });
  return text;
}
function send({ id, token }, message) {
  const url = `https://api.telegram.org/bot${token}/sendMessage`;
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      chat_id: id,
      text: message,
      parse_mode: "HTML",
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
