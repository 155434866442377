import refs from "./refs";

const { bodyEl } = refs;

let currentBackdrop = null;

export const showBackdrop = (backdrop, hideOnResize = false) => {
  if (!backdrop) {
    return;
  }
  disableBodyScroll();

  backdrop.removeClass("is-hidden");
  backdrop.on("click", handleBackdropClick);
  $(window).on("keydown", handleKeyDown);
  currentBackdrop = backdrop;

  if (hideOnResize) {
    $(window).on("resize", throttledHandleResize);
  }
};

export const hideBackdrop = (backdrop) => {
  if (!backdrop) {
    return;
  }

  enableBodyScroll();

  backdrop.addClass("is-hidden");
  backdrop.removeClass("click", handleBackdropClick);
  $(window).off("keydown", handleKeyDown);
  $(window).off("resize", throttledHandleResize);

  currentBackdrop = null;
};

function handleBackdropClick(e) {
  if (e.target === e.currentTarget) {
    hideBackdrop(currentBackdrop);
  }
}

function handleKeyDown(e) {
  if (e.key === "Escape") {
    hideBackdrop(currentBackdrop);
  }
}

function handleResize() {
  const { innerWidth } = window;

  if (innerWidth >= 768) {
    hideBackdrop(currentBackdrop);
  }
}

export function enableBodyScroll() {
  bodyEl.css("overflow-y", "scroll");
}

export function disableBodyScroll() {
  bodyEl.css("overflow-y", "hidden");
}

document.addEventListener("DOMContentLoaded", function () {
  const forms = document.querySelectorAll(".wpcf7-form");
  if (forms.length > 0) {
    forms.forEach((form) => {
      form.addEventListener("submit", function (event) {
        event.preventDefault();

        const formData = new FormData(form);
        fetch(form.action, {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            if (response.ok) {
              // const tyWrapper = form
              //   .closest(".cta-wrapper__form")
              //   ?.querySelector(".ty-wrapper");
              // if (tyWrapper) {
              //   tyWrapper.classList.remove("is-hidden");
              // } else {
              //   console.warn("Не знайдено елемент .ty-wrapper");
              // }
            } else {
              console.error("Помилка при відправленні форми:", response.status);
            }
          })
          .catch((error) => {
            console.error("Помилка при відправленні форми:", error);
          });
      });
    });
  } else {
    console.warn("Не знайдено елементів .wpcf7-form");
  }
});

// Перевірка на існування елементів з класом .citys-heading-list__link
if (document.querySelector(".citys-heading-list__link")) {
  const links = document.querySelectorAll(".citys-heading-list__link");

  // Додаємо клас 'active' до першого елемента за замовчуванням
  if (links.length > 0) {
    links[0].classList.add("active");
  }

  // Додаємо обробник подій для кожного елемента
  links.forEach((link) => {
    link.addEventListener("click", function () {
      // Видаляємо клас 'active' у всіх елементів
      links.forEach((link) => {
        link.classList.remove("active");
      });
      // Додаємо клас 'active' до клікнутого елемента
      this.classList.add("active");
    });
  });
}

$("document").ready(function () {
  bodyEl.css("visibility", "visible");
});

document.addEventListener("DOMContentLoaded", function () {
  document
    .querySelector("#single-doctor .image-styky .playVideo")
    ?.addEventListener("click", function () {
      document
        .querySelector("#single-doctor .image-styky .playVideo")
        .classList.add("show");
      document.querySelector("#single-doctor .image-styky video").play();
      document.querySelector("#single-doctor .image-styky video").muted = false;
    });

  document
    .querySelector("#landing-2 #hero .play, #landing-3 #hero .play")
    ?.addEventListener("click", function () {
      console.log("Play");
      const selector =
        window.innerWidth < 992
          ? "#landing-2 #hero .container video, #landing-3 #hero .container video"
          : "#landing-2 #hero .container video, #landing-3 #hero video";
      console.log(
        document.querySelector(
          "#landing-2 #hero [play], #landing-3 #hero [play]"
        )
      );
      if (
        document.querySelector(
          "#landing-2 #hero:has([play]), #landing-3 #hero:has([play])"
        )
      ) {
        document
          .querySelector(
            "#landing-2 #hero .image-thumb, #landing-3 #hero .image-thumb"
          )
          .removeAttribute("play");
        document.querySelector(selector).pause();

        return;
      }
      document
        .querySelector(
          "#landing-2 #hero .image-thumb, #landing-3 #hero .image-thumb"
        )
        .setAttribute("play", "");
      document.querySelector(selector).play();
      document.querySelector(selector).muted = false;
      observer.observe(document.querySelector(selector));

      document.querySelector(selector).addEventListener("click", function () {
        document.querySelector(selector).pause();
        document
          .querySelector(
            "#landing-2 #hero .image-thumb, #landing-3 #hero .image-thumb"
          )
          .removeAttribute("play");
      });
    });

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          entry.target.pause();
          entry.target.currentTime = 0;
          document
            .querySelector(
              "#landing-2 #hero .image-thumb, #landing-3 #hero .image-thumb"
            )
            .removeAttribute("play");
          observer.unobserve(entry.target);
        }
      });
    },
    {
      rootMargin: "0px",
      threshold: 0.1,
    }
  );
});

document.addEventListener("DOMContentLoaded", function () {
  const button = document.querySelector(".popup-burger");
  const popup = document.querySelector(".backdrop.burger");
  if (!button || !popup) return;
  const closeButtons = popup.querySelector(".close-button");

  button.addEventListener("click", function () {
    popup.hasAttribute("open")
      ? popup.removeAttribute("open")
      : popup.setAttribute("open", "");
  });
  closeButtons?.addEventListener("click", function () {
    popup.removeAttribute("open");
  });
  window.addEventListener("resize", function () {
    popup.removeAttribute("open");
  });
  popup.addEventListener("click", function (e) {
    if (e.target === e.currentTarget) {
      popup.removeAttribute("open");
    }
  });
  popup.querySelectorAll("a, button").forEach((el) => {
    el.addEventListener("click", () => {
      popup.removeAttribute("open");
    });
  });
});
