import Swiper from "swiper/bundle";

const swiper = new Swiper("#certificates .swiper", {
  slidesPerView: 1,
  spaceBetween: 20,
  speed: 1000,
  navigation: {
    nextEl: "#certificates .next",
    prevEl: "#certificates .prev",
  },
  breakpoints: {
    768: {
      slidesPerView: 4.5,
      centeredSlides: false,
    },
  },
  autoHeight: true,
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  on: {
    init: function () {
      if (window.innerWidth < 768) {
        updateSlides(this);
      } else {
        this.slideTo(1);
      }
      console.log(this.slides);
      document
        .querySelector(".backdrop.foolscreen")
        ?.addEventListener("click", (e) => {
          if (e.target === e.currentTarget)
            e.currentTarget.removeAttribute("open");
        });
      window.addEventListener("resize", () => {
        document.querySelector(".backdrop.foolscreen")?.removeAttribute("open");
      });
      this.slides.forEach((slide) => {
        const events = {
          click: (e) => {
            function openPopup(e) {
              const popup = document.querySelector(".backdrop.foolscreen");
              if (!popup) return;
              popup.querySelector(".content").innerHTML = "";
              popup
                .querySelector(".content")
                .appendChild(e.target.cloneNode(true));
              popup.setAttribute("open", "");
            }
            if (window.innerWidth < 768) {
              if (
                e.target
                  .closest(".swiper-slide")
                  .classList.contains("swiper-slide-active")
              ) {
                openPopup(e);
              }
            } else if (
              e.target
                .closest(".swiper-slide")
                .classList.contains("swiper-slide-next")
            ) {
              openPopup(e);
            }
            const index = this.slides.indexOf(
              e.target.closest(".swiper-slide")
            );
            console.log("Click to slide: ", slide, index);
            this.update();
            if (
              e.target
                .closest(".swiper-slide")
                .classList.contains("swiper-slide-next") ||
              window.innerWidth < 768
            )
              return;
            this.slideTo(index - 1);
          },
          transitinEnd: (e) => {
            if (
              !e.target
                .closest(".swiper-slide")
                .classList.contains("swiper-slide-next") ||
              e.propertyName !== "max-height"
            )
              return;
            console.log("Transition end: ", e);
            this.wrapperEl.style.height =
              this.slides[this.activeIndex + 1].offsetHeight + "px";
          },
        };
        slide.addEventListener("click", events.click.bind(this));
        slide.addEventListener("transitionend", events.transitinEnd.bind(this));
      });
    },
    slideChangeTransitionStart: function () {
      console.log("Slide change transition start");
      this.wrapperEl.style.transitionDuration = "1000ms";
    },
    breakpoint: function () {
      console.log("Breakpoint");
      updateSlides(this);
    },
  },
});
function updateSlides(swiper) {
  if (window.innerWidth < 768) {
    swiper.slides = [
      ...swiper.slides.filter((slide) => {
        if (slide.classList.contains("hidden")) {
          slide.remove();
          return false;
        }
      }),
    ];
  } else {
    if (swiper.wrapperEl.querySelector(".hidden")) return;
    const hiddenSlide = document.createElement("div");
    hiddenSlide.classList.add("swiper-slide", "hidden");
    swiper.prependSlide(hiddenSlide);
    swiper.update();

    for (let i = 0; i < 3; i++) {
      const hiddenSlideEnd = hiddenSlide.cloneNode(true);
      swiper.appendSlide(hiddenSlideEnd);
      swiper.update();
    }
  }
  swiper.update();
}

const clinical = new Swiper("#clinical .swiper", {
  autoHeight: true,
  spaceBetween: 40,
  navigation: {
    nextEl: "#clinical .next",
    prevEl: "#clinical .prev",
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  pagination: {
    el: "#clinical .pagination",
    clickable: true,
    dynamicBullets: true,
  },
  slidesPerView: 1,
});

//revievs
const revievs = new Swiper("#landing-3 #reviews .swiper", {
  slidesPerView: 1,
  breakpoints: {
    768: {
      slidesPerView: "auto",
    },
  },
  spaceBetween: 20,
  autoHeight: true,
  loop: true,
  navigation: {
    prevEl: "#landing-3 #reviews .prev",
    nextEl: "#landing-3 #reviews .next",
  },
  on: {
    init: function () {
      this.slides.forEach((slide) => {
        slide.addEventListener("click", (e) => {
          document.querySelector(".backdrop.foolscreen .content").innerHTML =
            "";
          document
            .querySelector(".backdrop.foolscreen .content")
            .appendChild(e.target.cloneNode(true));
          document
            .querySelector(".backdrop.foolscreen")
            .setAttribute("open", "");
        });
      });
    },
  },
});

// blog

const blog = new Swiper("#blog .swiper", {
  init: false,
  slidesPerView: 1,
  speed: 1000,
  navigation: {
    nextEl: "#blog .next",
    prevEl: "#blog .prev",
  },
  pagination: {
    el: "#blog .pagination",
    clickable: true,
    dynamicBullets: true,
  },
});

document.addEventListener("DOMContentLoaded", () => {
  const posts = [
    ...document.querySelectorAll("#blog .blog.desktop .sbi_photo"),
  ].map((post, id) => {
    const img = post.querySelector("img");
    img.setAttribute("data-index", id);
    return {
      link: post.href,
      img,
    };
  });
  const observer = new MutationObserver((mutationsList, observer) => {
    for (let mutation of mutationsList) {
      if (mutation.type === "attributes") {
        document
          .querySelector("#blog .blog.desktop")
          .classList.remove("mobile-inited");
        [...document.querySelectorAll("#blog .blog.mobile .swiper-slide")][
          mutation.target.getAttribute("data-index")
        ].querySelector("img").src = mutation.target.src;
        document
          .querySelector("#blog .blog.desktop")
          .classList.add("mobile-inited");
        console.log(blog);
      }
    }
  });
  posts.forEach((post) => {
    observer.observe(post.img, { attributes: true, attributeFilter: ["src"] });
    document
      .querySelector("#blog .blog.mobile .swiper-wrapper")
      ?.appendChild(elementFrom(post));
  });
  blog.init();
  function elementFrom({ link, img }) {
    let imgEl = img.cloneNode(true),
      template = `
			<div class="swiper-slide post">
			<a href="${link}">
			</a>
			</div>
		`,
      pars = new DOMParser().parseFromString(template, "text/html").body
        .firstChild;
    pars.querySelector("a").appendChild(imgEl);
    return pars;
  }
});
