document.addEventListener("DOMContentLoaded", function () {
  let input = document.querySelector(
    '.main-nav__wrapper form input[type="search"]'
  );
  if (!input) return;
  switch (document.documentElement.lang) {
    case "uk":
      input.setAttribute("placeholder", "Пошук...");
      break;
    case "ro":
      input.setAttribute("placeholder", "Cautare...");
      break;
    case "ru":
      input.setAttribute("placeholder", "Поиск...");
      break;
    default:
      input.setAttribute("placeholder", "Search...");
  }
});
